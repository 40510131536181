import Header from "components/Header";
import SignupForm from "components/SignUpForm";

export default function SignupPage(){
    return (
        <>
        <Header />
        <SignupForm />
        </>
    );
}