import AuthContext from "context/AuthContext";
import { collection, doc, getDocs, deleteDoc, orderBy, query, where } from "firebase/firestore";
import { db } from "firebaseApp";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

interface PostListProps {
    hasNavigation?: boolean;
    defaultTab?: TabType | CategoryType; 
}

export interface CommentsInterface {
    content: string;
    uid: string;
    email: string;
    createdAt: string;
}

export interface PostProps {
    id?: string; 
    title: string;
    email: string;
    summary: string;
    content: string;
    createAt: string;
    updatedAt?: string;
    uid:string;
    category?:CategoryType;
    comments?:CommentsInterface[];
}

type TabType = "all" | "my";

export type CategoryType = "Frontend" | "Backend" | "Web" | "Native";
export const CATEGORIES: CategoryType[] = ["Frontend", "Backend", "Web", "Native"];

export default function PostList({ hasNavigation = true, defaultTab = "all", }: PostListProps) {
    const [activetab, setActiveTab] = useState<TabType | CategoryType>(
        defaultTab
    );
    const [posts, setPosts] = useState<PostProps[]>([]);
    const {user} = useContext(AuthContext);

    const getPosts = async () => {

        setPosts([]); //호출 하는 post data 를 초기화 해줘야 함 
        
        let postsRef = collection(db, "posts"); 
        let postsQuery;

        if(activetab === 'my' && user){
            //나의 글만 필터링 
            postsQuery = query(
                                postsRef, 
                                where('uid', '==', user?.uid), 
                                orderBy("createAt", "asc")
            );
        }else if(activetab === 'all'){
            //모든 글 보여주기 
            postsQuery = query(postsRef, orderBy("createAt", "asc"));
        }else{
           //카테고리 글 보여주기 
           postsQuery = query(postsRef, where("category", "==", activetab), orderBy("createAt", "asc"));
        }

        const datas = await getDocs(postsQuery);

        datas?.forEach((doc) => {
               const dataObj = {...doc.data(), id: doc.id};
               setPosts((prev)=>[...prev, dataObj as PostProps]);
        });
    };

    useEffect(() => {
        getPosts();
    }, [activetab]);

    const handleDelete = async (id: string) => {
        const confirm = window.confirm("해당 게시글을 삭제 하시겠습니까?");
        if(confirm && id){
            await deleteDoc(doc(db, "posts", id));
            toast.success("게시글을 삭제했습니다.");
            getPosts(); //변경된 post list 를 다시 가져 오도록 
        }    
    };

    return (
        <>
        {hasNavigation && (
        <div className="post__navigation">
            <div 
                role="presentation" 
                onClick={() => setActiveTab("all")} 
                className={activetab === "all" ? "post__navigation--active" : ""}>
                    전체
            </div>
            <div 
                role="presentation" 
                onClick={() => setActiveTab("my")} 
                className={activetab === "my" ? "post__navigation--active" : ""}>
                    나의글
            </div>
            {CATEGORIES?.map((category) => (
                <div 
                   key={category}
                   role="presentation" 
                   onClick={() => setActiveTab(category)} 
                   className={activetab === category ? "post__navigation--active" : ""}>
                       {category}
               </div> 
            ))}
        </div>
        )}
        <div className="post__list">
                {posts?.length > 0 ? posts?.map((post, index) => (
                    <div key={post?.id} className="post__box">
                        <Link to={`/posts/${post?.id}`}>
                            <div className="post__profile-box">
                                <div className="post__profile" />
                                <div className="post__author-name">{post?.email}</div>
                                <div className="post__date">{post?.createAt}</div>
                            </div>
                            <div className="post__title">{post?.title}</div>
                            <div className="post__text">
                                {post?.summary}
                            </div>
                            </Link>
                            {post?.email === user?.email && (
                                <div className="post__utils-box">
                                    <div className="post__delete" role="presentation" onClick={() => handleDelete(post.id as string)}>삭제</div>
                                        <Link to={`/posts/edit/${post?.id}`} className="post__edit">수정</Link>
                                    </div>
                            )}
                    </div>
                ))
                : (
                    <div className="post__no-post">게시글이 없습니다.</div>
                )}
            </div>  
            </>
    );   
}