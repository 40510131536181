import PostForm from "components/PostForm";
import Header from "components/Header";

export default function PostNew(){
    return (
        <>
            <Header />
            <PostForm />
        </>
    );
}