import Footer from "components/Footer";
import Header from "components/Header";
import PostDetail from "components/PostDetail";

export default function PostPage() {
    return (
     <>
        <Header />
        <PostDetail />
        <Footer />
     </>    
    );
}