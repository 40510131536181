import Header from "components/Header";
import LoginForm from "components/LoginForm";

export default function LoginPage(){
    return (
        <>
          <Header />
          <LoginForm />
        </>
    );
}