import { ReactNode, createContext, useState } from "react";

const ThemeContext = createContext({
    theme: 'light',
    toggleMode: () => {},
});

interface ThemeProps {
    children: ReactNode;
}

export const ThemeContextProvider = ({ children }: ThemeProps) => {
    const [theme, SetTheme] = useState(window.localStorage.getItem("theme") || "light");
    const toggleMode = () => {
        SetTheme((prev) => (prev === "light" ? "dark" : "light"));
        window.localStorage.setItem("theme", theme === "light" ? "dark" : "light"); 
    };

    return (
        <ThemeContext.Provider value={{ theme, toggleMode}}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeContext;